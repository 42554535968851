//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Loader } from "@googlemaps/js-api-loader";

export default {
  data() {
    return {
      map: null,
      directionsService: null,
      directionsRenderer: null,
      startLocation: null,
      destination: null,
      distance: null,
      startLocationInput: null,
      destinationInput: null,
      startSuggestions: [],
      destinationSuggestions: [],
      autocompleteStart: null,
      autocompleteDestination: null,
      selectedPoint: "start",
      startMarker: null,
      destinationMarker: null,
      mapVisible: true,
    };
  },
  mounted() {
    this.initMap();
    this.$hideLoader();
  },
  methods: {
    initMap() {
      const loader = new Loader({
        apiKey: "AIzaSyCnr4RYRiu6AImS6Ky5SGOyR8qX39bgHsw", // แทนที่ด้วย API Key ของคุณ
        version: "weekly",
        libraries: ["places"],
      });

      loader.load().then(() => {
        this.map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: 13.7563, lng: 100.5018 },
          zoom: 13,
        });

        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer();
        this.directionsRenderer.setMap(this.map);

        this.map.addListener("click", this.handleMapClick);
      });
    },

    toggleMap() {
  this.mapVisible = !this.mapVisible;

  // เมื่อเปิดแผนที่ใหม่ ตรวจสอบว่าแผนที่ถูกสร้างแล้วหรือไม่
  if (this.mapVisible && !this.map) {
    this.initMap();  // หากแผนที่ยังไม่ได้ถูกสร้างให้สร้างใหม่
  } else if (!this.mapVisible) {
    // รีเซ็ตค่าทั้งหมดเมื่อแผนที่ถูกปิด
    this.resetMapData();
  }
},

// ฟังก์ชันสำหรับรีเซ็ตข้อมูลแผนที่
resetMapData() {
  // รีเซ็ตค่าต่างๆ ที่เกี่ยวข้องกับสถานที่
  this.startLocation = null;
  this.destination = null;
  this.startLocationInput = null;
  this.destinationInput = null;
  this.distance = null;

  // ลบมาร์กเกอร์เก่า
  if (this.startMarker) {
    this.startMarker.setMap(null);
    this.startMarker = null;
  }
  if (this.destinationMarker) {
    this.destinationMarker.setMap(null);
    this.destinationMarker = null;
  }

  // รีเซ็ต autocomplete suggestions
  this.startSuggestions = [];
  this.destinationSuggestions = [];

  // รีเซ็ตค่าของ autocomplete inputs
  if (this.autocompleteStart) {
    this.autocompleteStart.set('place', null); // เคลียร์ค่า autocomplete start
  }
  if (this.autocompleteDestination) {
    this.autocompleteDestination.set('place', null); // เคลียร์ค่า autocomplete destination
  }

  // รีเซ็ตแผนที่
  if (this.directionsRenderer) {
    this.directionsRenderer.setMap(null);
  }
  if (this.directionsService) {
    this.directionsService = null;
  }

  // หากต้องการให้แผนที่กลับไปที่ตำแหน่งเริ่มต้น
  if (this.map) {
    this.map.setCenter({ lat: 13.7563, lng: 100.5018 });
    this.map.setZoom(13);
  }
},
  
    initializeAutocomplete(type) {
      const inputElement = type === "start" 
        ? this.$refs.startInput.$el.querySelector("input") 
        : this.$refs.destinationInput.$el.querySelector("input");

      const autocomplete = new google.maps.places.Autocomplete(inputElement);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) return;

        // กำหนดข้อมูลสถานที่และการปักหมุดบนแผนที่
        if (type === "start") {
          this.startLocation = place.geometry.location;
          this.startLocationInput = place.formatted_address; // เก็บค่าที่เลือกจาก autocomplete

          // ลบมาร์กเกอร์เก่าถ้ามี แล้วเพิ่มมาร์กเกอร์ใหม่สำหรับจุดเริ่มต้น
          if (this.startMarker) {
            this.startMarker.setMap(null);
          }
          this.startMarker = new google.maps.Marker({
            position: this.startLocation,
            map: this.map,
            icon: { url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png" }
          });

          // ตั้งค่าแผนที่ให้โฟกัสไปยังตำแหน่งที่ค้นหา
          this.map.panTo(this.startLocation);

        } else if (type === "destination") {
          this.destination = place.geometry.location;
          this.destinationInput = place.formatted_address; // เก็บค่าที่เลือกจาก autocomplete

          // ลบมาร์กเกอร์เก่าถ้ามี แล้วเพิ่มมาร์กเกอร์ใหม่สำหรับจุดปลายทาง
          if (this.destinationMarker) {
            this.destinationMarker.setMap(null);
          }
          this.destinationMarker = new google.maps.Marker({
            position: this.destination,
            map: this.map,
            icon: { url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" }
          });

          // ตั้งค่าแผนที่ให้โฟกัสไปยังตำแหน่งที่ค้นหา
          this.map.panTo(this.destination);
        }

        // เช็คว่าผู้ใช้เลือกจุดเริ่มต้นและปลายทางครบหรือไม่
        if (this.startLocation && this.destination) {
          
          this.calculateDistance(); // คำนวณระยะทางทันทีเมื่อเลือกครบทั้งสองจุด
        }
      });

      if (type === "start") {
        this.autocompleteStart = autocomplete;
      } else {
        this.autocompleteDestination = autocomplete;
      }
    },


    handleMapClick(e) {
      if (this.selectedPoint === "start") {
        this.startLocation = e.latLng;
        // ตรวจสอบว่าไม่ให้ค่าของ startLocationInput หาย
        if (!this.startLocationInput) {
          this.startLocationInput = "Latitude: " + e.latLng.lat() + ", Longitude: " + e.latLng.lng();
        }

        if (this.startMarker) {
          this.startMarker.setMap(null);
        }

        this.startMarker = new google.maps.Marker({
          position: e.latLng,
          map: this.map,
          icon: { url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png" },
        });

        this.selectedPoint = "destination"; // เปลี่ยนเป็นจุดปลายทาง
      } else {
        this.destination = e.latLng;
        // ตรวจสอบว่าไม่ให้ค่าของ destinationInput หาย
        if (!this.destinationInput) {
          this.destinationInput = "Latitude: " + e.latLng.lat() + ", Longitude: " + e.latLng.lng();
        }

        if (this.destinationMarker) {
          this.destinationMarker.setMap(null);
        }

        this.destinationMarker = new google.maps.Marker({
          position: e.latLng,
          map: this.map,
          icon: { url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png" },
        });

        this.selectedPoint = "start"; // เปลี่ยนเป็นจุดเริ่มต้น
      }

      // เช็คว่าผู้ใช้เลือกจุดเริ่มต้นและปลายทางครบหรือไม่
      if (this.startLocation && this.destination) {

        this.calculateDistance(); // คำนวณระยะทางทันทีเมื่อเลือกครบทั้งสองจุด
      }
    },


    onLocationInput(type) {
      const inputElement = type === "start"
        ? this.$refs.startInput.$el.querySelector("input")
        : this.$refs.destinationInput.$el.querySelector("input");

      // กรณีที่ผู้ใช้เลือกหรือพิมพ์ที่ช่องค้นหา
      if (type === "start") {

        this.startLocationInput = inputElement.value;
      } else {
        this.destinationInput = inputElement.value;
      }

      // เช็คว่าผู้ใช้เลือกจุดเริ่มต้นและปลายทางครบหรือไม่
      if (this.startLocation && this.destination) {
        this.calculateDistance(); // คำนวณระยะทางทันทีเมื่อเลือกครบทั้งสองจุด
      }
    },

    onSearchInput(type, query) {
      const service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input: query }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (type === "start") {
            this.startSuggestions = predictions;
          } else {
            this.destinationSuggestions = predictions;
          }
        }
      });
    },


    calculateDistance() {
      if (!this.startLocation || !this.destination) {
        this.distance = "Please select both start and destination points.";
        return;
      }
      console.log("this.startLocation: " + this.startLocation)
      console.log("this.destination: " + this.destination)
      // const request = {
      //   origin: this.startLocation,
      //   destination: this.destination,
      //   travelMode: google.maps.TravelMode.DRIVING,
      // };

      // this.directionsService.route(request, (result, status) => {
      //   if (status === google.maps.DirectionsStatus.OK) {
      //     this.directionsRenderer.setDirections(result);
      //     this.distance = result.routes[0].legs[0].distance.text;
      //   } else {
      //     this.distance = "Error calculating distance.";
      //   }
      // });
    },
  },
};
