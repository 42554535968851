//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import SuccessDialog from "@/components/cards/SuccessDialog";

export default {
  name: "MasterTradeMark",
  async mounted() {
    this.$showLoader();
    await api.checkVersion();

    // ----------------- Check Authorize ---------------------------
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

       if (!this.authorize_view) {
      this.$router.push("/not-found");
    }

    // ----------------- Check Authorize ---------------------------
    await this.loaddataLimit200();
    await this.loadCustomer();
    this.$hideLoader();
  },
  components: { SuccessDialog, SuccessDialogPush },
  data() {
    return {
      userId: 0,
      authorize: [],
      authorize_view: false,
      authorize_add: false,
      authorize_edit: false,
      authorize_del: false,
      authorize_id: 0,
      load_bg: false,
      contact_type: [
        { contact_typenumber: 1, contact_typename: "Owner" },
        { contact_typenumber: 2, contact_typename: "Agent" },
        { contact_typenumber: 3, contact_typename: "Associate" },
      ],
      dialogAdd: false,
      dialogAddData: false,
      dialogDelete: false,
      isShowPassword: false,
      fullPage: true,
      search: "",
      mDataArray: [],
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      search_ct: "",
      itemdata: {
        contact_type: 0,
        mapping_cust_id:0,
        contact_name: "",
        contact_person: "",
        position: "",
        email: "",
        address: "",
        city: "",
        country: "",
        telephone: "",
        fax: "",
        status: "A",
      },
      edititemdata: {
        contact_type: 0,
        mapping_cust_id:0,
        contact_name: "",
        contact_person: "",
        position: "",
        email: "",
        address: "",
        city: "",
        country: "",
        telephone: "",
        fax: "",
      },
      contactid_del: 0,
      oparation: "",
      headers: [
        {
          text: "Contact Type",
          value: "contact_typeShow",
          class: "bg-colorth",
          divider: true,
          width: "8%",
        },
        {
          text: "Contact Name",
          value: "contact_name",
          class: "bg-colorth",
          divider: true,
          width: "10%",
        },
        {
          text: "Contact Person",
          value: "contact_person",
          class: "bg-colorth",
          divider: true,
          width: "8%",
        },
        {
          text: "Position",
          value: "position",
          class: "bg-colorth",
          divider: true,
          // width: "7%"
        },
        {
          text: "Address",
          value: "address",
          class: "bg-colorth",
          divider: true,
          width: "13%",
        },

        {
          text: "City",
          value: "city",
          class: "bg-colorth",
          divider: true,
          width: "10%",
        },
        {
          text: "Country",
          value: "country",
          class: "bg-colorth",
          divider: true,
          // width: "10%"
        },
        {
          text: "Telephone",
          value: "telephone",
          class: "bg-colorth",
          divider: true,
          // width: "10%"
        },
        {
          text: "Email",
          value: "email",
          class: "bg-colorth",
          divider: true,
          // width: "150px"
        },
        {
          text: "POA",
          value: "poa",
          class: "bg-colorth",
          divider: true,
          align: "center"
          // width: "150px"
        },
        {
          text: "",
          value: "actions",
          class: "bg-colorth",
          divider: true,
          width: "95px",
        },
      ],
      customer_name_list: [],
    };
  },
  methods: {
    async onSelectMappingCustomerChange(id) {
      this.customer_name_list.filter((item) => {
        if (item.id == id) {
          this.itemdata.contact_name = item.name;
          this.itemdata.contact_person = item.contact_name;
          this.itemdata.address = item.address;
          this.itemdata.city = item.provice;
          this.itemdata.country = item.country;
          return;
        }
      });
    },
    async loadCustomer() {
      const result = await api.getAllCustomerListactiveCompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      this.customer_name_list = result.data;
    },
    async loaddataLimit200() {
      // this.$showLoader();
      var tmp = [];
      const result = await api.getAllContactLimit200();
      tmp = result.data;
      tmp.forEach((item) => {
        if (item.contact_type == 1) {
          item.contact_typeShow = "Owner";
        } else if (item.contact_type == 2) {
          item.contact_typeShow = "Agent";
        } else {
          item.contact_typeShow = "Associate";
        }
      });
      // this.$hideLoader();

      this.mDataArray = tmp;
    },
    async loaddata() {
      // this.$showLoader();
      this.load_bg = true;
      var tmp = [];
      const result = await api.getAllContact();
      tmp = result.data;
      tmp.forEach((item) => {
        if (item.contact_type == 1) {
          item.contact_typeShow = "Owner";
        } else if (item.contact_type == 2) {
          item.contact_typeShow = "Agent";
        } else {
          item.contact_typeShow = "Associate";
        }
      });

      this.mDataArray = this.mDataArray.concat(tmp);
      // this.$hideLoader();
      this.load_bg = false;
    },
    async openadd() {
      this.itemdata = {
        contact_type: 0,
        mapping_cust_id: 0,
        contact_name: "",
        contact_person: "",
        position: "",
        email: "",
        address: "",
        city: "",
        country: "",
        telephone: "",
        fax: "",
        status: "A",
      };
      this.dialogAddData = true;
      this.oparation = "create";
    },
    async closeaddItem() {
      this.dialogAddData = false;
      this.itemdata = {
        contact_type: 0,
        mapping_cust_id: 0,
        contact_name: "",
        contact_person: "",
        position: "",
        email: "",
        address: "",
        city: "",
        country: "",
        telephone: "",
        fax: "",
        status: "A",
      };
      this.oparation = "";
    },
    openPOA(item) {
      // console.log(item)
      this.$router.push("/poa/"+item.contact_id);
    },
    openeditItem(item) {
      //console.log(item);
      this.oparation = "update";
      this.itemdata.contact_type = item.contact_type;
      this.itemdata.mapping_cust_id = item.mapping_cust_id;
      this.itemdata.contact_name = item.contact_name;
      this.itemdata.contact_person = item.contact_person;
      this.itemdata.position = item.position;
      this.itemdata.email = item.email;
      this.itemdata.address = item.address;
      this.itemdata.city = item.city;
      this.itemdata.country = item.country;
      this.itemdata.telephone = item.telephone;
      this.itemdata.fax = item.fax;
      //this.itemdata = item;
      this.dialogAddData = true;
      this.contactid_del = item.contact_id;
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.contactid_del = item;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      this.$showLoader();
      // eslint-disable-next-line prettier/prettier, no-undef
      const update_contact = await api.updateContact(this.contactid_del, {
        status: "D",
      });
      if (update_contact.status == 200 || update_contact.status == 201) {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "ลบข้อมูล",
          "ลบข้อมูลสำเร็จ",
          "text-h5 green--text text-center",
          "/contact-list"
        );
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ลบข้อมูล",
          "ไม่สามารถลบได้",
          "text-h5 red--text text-center"
        );
      }
      this.dialogDelete = false;
      await this.loaddataLimit200();
      this.$hideLoader();
    },
    async saveaddItem() {
      if (!this.itemdata.contact_type) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Select Contact Type",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (!this.itemdata.contact_name) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Select Contact Name",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (!this.itemdata.address) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Select Address",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (!this.itemdata.city) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Select City",
          "text-h5 red--text text-center"
        );
        return;
      }
      this.$showLoader();
      if (this.oparation == "create") {
        // console.log(this.itemdata);
        const create_contact = await api.createContact(this.itemdata);
        //alert(create_contact.status);
        if (create_contact.status == 200 || create_contact.status == 201) {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "บันทึกข้อมูล",
            "บันทึกข้อมูลสำเร็จ",
            "text-h5 green--text text-center",
            "/contact-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "บันทึกข้อมูล",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
        //console.log(this.itemdata);
        this.edititemdata.contact_type = this.itemdata.contact_type;
        this.edititemdata.mapping_cust_id = this.itemdata.mapping_cust_id;
        this.edititemdata.contact_name = this.itemdata.contact_name;
        this.edititemdata.contact_person = this.itemdata.contact_person;
        this.edititemdata.position = this.itemdata.position;
        this.edititemdata.email = this.itemdata.email;
        this.edititemdata.address = this.itemdata.address;
        this.edititemdata.city = this.itemdata.city;
        this.edititemdata.country = this.itemdata.country;
        this.edititemdata.telephone = this.itemdata.telephone;
        this.edititemdata.fax = this.itemdata.fax;
        const update_contact = await api.updateContact(
          this.contactid_del,
          this.edititemdata
        );
        if (update_contact.status == 200 || update_contact.status == 201) {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "บันทึกข้อมูล",
            "บันทึกข้อมูลสำเร็จ",
            "text-h5 green--text text-center",
            "/contact-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "บันทึกข้อมูล",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      }
      //console.log(this.itemdata);
      this.closeaddItem();
      await this.loaddataLimit200();
      this.$hideLoader();
    },
    async onSearch() {
      //alert("5555")
      const result = await api.getMasterContactSearch({
        params: { search_ct: this.search_ct.trim() },
      });
      this.mDataArray = result.data;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
