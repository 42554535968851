//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server, imageUrl } from "@/services/constants";
import { language } from "../language/Leavelist.js";
import unity from "@/unity/unity";

export default {
  name: "leave-list",
  data(vm) {
    return {
      authorize: [],
      search: "",
      menu: false,
      menu2: false,
      selectstatus: "",
      dataAll: [],
      status: ["Draft", "Submit"],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],

      page: 1, // หน้าเริ่มต้น
      itemsPerPage: 5, // จำนวนรายการต่อหน้า
      leavehistory_list: [],
      datashowAll: [],
    };
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.headers = language.en.headertable;
    // this.initialize();
  },
  beforeUpdate() {},
  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
    pageCount() {
      return Math.ceil(this.datashowAll.length / this.itemsPerPage); // คำนวณจำนวนหน้าทั้งหมด
    },
    paginatedItems() {
      const start = (this.page - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.datashowAll.slice(start, end); // ดึงรายการที่ต้องแสดงในแต่ละหน้า
    }
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

       if (!this.authorize_view) {
      this.$router.push("/not-found");
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.loaddataLeaveRequest(this.userId);
    await this.loadleaveHistory(this.userId);
    this.$hideLoader();
  },
  methods: {
    async loadleaveHistory(){      
      const res_leave_history = await api.getListApproveAll(localStorage.getItem(server.USER_ID));
      console.log("res_leave_history: ")
      console.log(res_leave_history.data)

      for( const [index,item] of res_leave_history.data.entries()){
        if(item.leave_type == "Sick Leave"){
          item.leave_type = "Sick"
        }else if(item.leave_type == "Vacation Leave"){
          item.leave_type = "Vacation"
        }
        var leave_day = 0;
        var leave_hours = 0;

        leave_day = Math.floor(Number(item.hours_amount) / 8)
        leave_hours =Number(item.hours_amount) - (leave_day * 8);

        if(leave_day == 0){
          if(leave_hours == 0){
            item.description = `${item.data_date}`
          }else{
            item.description = item.description = `${item.data_date} (${leave_hours} hours)`
          }
        }else{
          if(leave_hours == 0){
            item.description = `${item.data_date} (${leave_day} day)`
          }else{
            item.description = item.description = `${item.data_date} (${leave_day} day ${leave_hours} hours)`
          }
        }

        if (item.image != "" && item.image != null) {
          item.image_profile = imageUrl + "/" + item.image;
        }else{
          item.image_profile = ""
        }
       
      }
      this.leavehistory_list = res_leave_history.data
      this.datashowAll = res_leave_history.data
 
    },
    async goToLeaveApproveById(id,user_id) {
      // เปลี่ยน URL หรือทำการนำทางที่ต้องการ
      this.$router.push(`/approve-leave-confirm/${id}/${user_id}`); // หากคุณใช้ Vue Router
      // หรือ window.location.href = 'URL ที่ต้องการ'; // หากคุณต้องการเปลี่ยนไปยัง URL อื่น
    },
    async search_data(){
      const searchTerm = this.search.toLowerCase();
      if(this.datefrom && this.dateto){
        this.datashowAll = this.leavehistory_list.filter(item => {
          // ตรวจสอบว่าชื่อหรือประเภทการลา (leave_type) มีคำค้นหาหรือไม่
          return (
            (
              item.name_eng.toLowerCase().includes(searchTerm) ||
              item.name_th.toLowerCase().includes(searchTerm) ||
              item.leave_type.toLowerCase().includes(searchTerm) 
            ) && (
              this.datefrom <= item.str_time && this.dateto >= item.end_time
            )
          );
        });
      }else{
        this.datashowAll = this.leavehistory_list.filter(item => {
          // ตรวจสอบว่าชื่อหรือประเภทการลา (leave_type) มีคำค้นหาหรือไม่
          return (
            item.name_eng.toLowerCase().includes(searchTerm) ||
            item.name_th.toLowerCase().includes(searchTerm) ||
            item.leave_type.toLowerCase().includes(searchTerm)
          );
        });
      }
    },
    previousPage() {
      if (this.page > 1) {
        this.page -= 1;
      }
    },
    nextPage() {
      if (this.page < this.pageCount) {
        this.page += 1;
      }
    },


    async loaddataLeaveRequest(id) {
      // const result = await api.getListApproveByApproverIDbyCompany(id,localStorage.getItem(server.COMPANYID));
      const result = await api.getListApproveIdNow(id);
      this.mDataArray = result.data;

      // console.log("dateAll:")
      //   console.log(this.dataAll)
      //   console.log("loadLeave", JSON.stringify(this.dataAll));

      // image

      this.mDataArray.forEach((item) => {
        var tmp = {
          id: 13,
          employee_id: "163",
          startDate: "2022-06-20T01:30:00.000Z",
          endDate: "2022-06-20T10:30:00.000Z",
          startDate2: "2022-06-20 08:30:00",
          endDate2: "2022-06-20 17:30:00",
          amount: "8.00",
          leaveTypeId: 2,
          leave_type: "ลากิจ",
          description: "test emp",
          description_approve: "test approve emp",
          approve_id: 1,
          approve_name: "Request",
          approver_level1: 80,
          fullName: "นาย Test Employee",
          image: "",
        };
        tmp.id = item.id;
        tmp.employee_id = item.employee_id;
        tmp.startDate = item.startDate;
        tmp.endDate = item.endDate;
        tmp.startDate2 = item.startDate2;
        tmp.endDate2 = item.endDate2;
        tmp.startDateS = item.startDateS;
        tmp.endDateS = item.endDateS;
        tmp.amount = item.amount;
        tmp.amount_show = this.quotaListConvert(item.amount / 8);
        tmp.leaveTypeId = item.leave_type_id;
        tmp.leave_type = item.leave_type;
        tmp.description = item.description;
        tmp.description_approve = item.description_approve;
        tmp.approve_id = item.approve_id;
        tmp.approve_name = item.approve_name;
        tmp.approver_level1 = item.approver_level1;
        tmp.fullName = item.fullName;
        // tmp.image = item.image;

        if (item.image == "" || item.image == null) {
          tmp.image = imageUrl + "/personal.png";
        } else {
          tmp.image = imageUrl + "/" + item.image;
        }

        this.dataAll.push(tmp);
      });

      console.log(this.dataAll);

      // this.dataAll = result.data;
      // this.mDataArray = result.data;

      // this.$showLoader();

    },
    quotaListConvert: function(H) {
      var d = 0;
      var h = 0;
      var m = 0;
      var tmp1 = parseFloat(H * 480);
      var tmp_final;

      var tmp_d = tmp1 / 480;

      d = Math.floor(tmp_d);
      h = Math.floor((tmp_d - d) * 8);
      m = Math.floor(((tmp_d - d) * 8 - h) * 60);

      tmp_final = `${d} Days ${h} Hour ${m} Minute`;

      return tmp_final;
    },
  },
};
