//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const moment = require('moment-timezone');
import api from "@/services/api";
import {
  server,
  imagemasterTMUrl,
  filemasterTMUrl,
} from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import pdfvuer from "pdfvuer";
import html2pdf from "html2pdf.js";

export default {
  name: "MasterTrademark",
  async mounted() {
    this.$showLoader();
    await api.checkVersion();
    // ----------------- Check Authorize ---------------------------
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    // ----------------- Check Authorize ---------------------------
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
       if (!this.authorize_view) {
      this.$router.push("/not-found");
    }

    // ----------------- Check Authorize ---------------------------

    await this.loaddatamastertmLimit200();
    this.$hideLoader();

    // await this.loaddatamastertmAll();
  },
  watch: {
    show: function(s) {
      if (s) {
        this.getPdf();
      }
    },
    page: function(p) {
      if (
        window.pageYOffset <= this.findPos(document.getElementById(p)) ||
        (document.getElementById(p + 1) &&
          window.pageYOffset >= this.findPos(document.getElementById(p + 1)))
      ) {
        // window.scrollTo(0,this.findPos(document.getElementById(p)));
        document.getElementById(p).scrollIntoView();
      }
    },
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    pdf: pdfvuer,
  },
  data(vm) {
    return {
      userId: 0,
      authorize: [],
      authorize_view: false,
      authorize_add: false,
      authorize_edit: false,
      authorize_del: false,
      authorize_id: 0,
      search_tmp: "",
      showfilenameappointment: "",
      dialogAppointment: false,
      itemsPerPageArray: [10, 15, 20],
      pageSize: 3,
      pageSizes: [3, 6, 9],
      pageCount: 0,
      itemsPerPage: 100,
      load_bg: false,
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      dialogEditmastertm: false,
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      dialogAddmastertm: false,
      dialogDelete: false,
      deletedItem: 0,
      deletedIndex: 0,
      isShowPassword: false,
      fullPage: true,
      datefrom: vm.formatDate(new Date().toISOString().substr(0, 10)),
      dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      search: "",
      mDataArray: [],
      mDataArrayappointment: [],
      headers: [
        { text: "File Status", value: "file_status", divider: true },
        {
          text: "File No.",
          align: "left",
          // sortable: false,
          value: "file_no",
          width: "10%",
          divider: true,
        },
        { text: "Application No.", value: "app_no", divider: true },
        { text: "Registration No.", value: "regis_no", divider: true },
        { text: "Class No.", value: "class_no", divider: true },
        { text: "Trademark Name", value: "trademark", divider: true },
        { text: "Mark Status", value: "tmupdatestatus_name", divider: true },
        { text: "Client Ref", value: "cliant_ref", divider: true },
        { text: "Owner", value: "owner_contact_name", divider: true },
        { text: "Agent", value: "agent_contact_name", divider: true },
        { text: "Next Renew", value: "date_of_renewal_show", divider: true },
        {
          text: "Associate",
          value: "associate_contact_name",
          divider: true,
        },
        {
          text: "",
          value: "actions",
          width: "15%",
          align: "left",
          divider: true,
        },
      ],
      headersappointment: [
        {
          text: "File No.",
          align: "left",
          // sortable: false,
          value: "refno",
          divider: true,
        },
        { text: "Application No.", value: "app_no", divider: true },
        { text: "Trademark Name", value: "trademark_name", divider: true },
        { text: "Agent", value: "agent_contact_name", divider: true },

        {
          text: "Work Type",
          align: "left",
          // sortable: true,
          value: "documenttype_code",
          width: "10%",
          divider: true,
        },
        { text: "Notice Type", value: "notice_name", divider: true },
        { text: "Final Deadline", value: "enddate", divider: true },
        { text: "Filed Date", value: "filed_date", divider: true },
        { text: "Status", value: "statusnow", divider: true },
        {
          text: "",
          value: "actions",
          width: "15%",
          align: "left",
          divider: true,
        },
      ],
      showprint: "display:none;",
      print_data: {},
      previewimageedit: null,
    };
  },
  methods: {
    async openPrintAppNo(item) {
      this.$showLoader();
      this.print_data = {};
      this.previewimageedit = null;
      await this.genimage(item);
      this.print_data = item;

      var dataAdd = {
            user_id: localStorage.getItem(server.USER_ID),
            log_in_out_id: localStorage.getItem(server.LOGIN_ID),
            action: "Export",
            table_name: "mastertm",
            table_id: item.mastertm_id,
            date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }

        await api.addTransectionLog(dataAdd)

        var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

        
      setTimeout(async () => {
        await this.exportToPDF();
        this.$hideLoader();
      }, "2000");
    },
    async genimage(item) {
      this.previewimageedit = `${imagemasterTMUrl}/${item.mastertm_id}/${item.master_images}`;
    },
    async exportToPDF(advance_id) {
      // this.$refs.html2Pdf.generatePdf();

      this.showprint = "";
      let element = document.getElementById("mydivhtmltobase");
      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
          console.log(pdf);
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          return pdf.output("bloburl");
        });
      // console.log(abcd);
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      this.showprint = "display:none;";

      return;
    },
    async openeditTableAppointment(appointment_id) {
      window.open(`/appointmenttm-edit/${appointment_id}`);
    },
    async onSearch() {
      this.$showLoader();
      const result = await api.getMasterTMSearch({
        params: { search_tmp: this.search_tmp.trim() },
      });
      this.mDataArray = result.data;
      this.$hideLoader();
      // this.search = this.search_tmp;
    },
    async loaddatamastertmLimit200() {
      // this.$showLoader();

      const result = await api.getAllMasterTMLimit200();
      console.log(result.data);
      // const result = await api.getAllMasterTM();
      this.mDataArray = result.data;
      // this.$hideLoader();
    },
    async loaddatamastertmAll() {
      // this.$showLoader();
      this.load_bg = true;
      const result = await api.getAllMasterTM();
      console.log(result.data);
      // const result = await api.getAllMasterTM();
      this.mDataArray = this.mDataArray.concat(result.data);

      // this.$hideLoader();
      // alert("load data success");
      // alert(this.mDataArray.length);
      this.load_bg = false;
    },
    async openAppointmentedit(file_no) {
      this.mDataArrayappointment = [];
      const result = await api.getfile_no(file_no);
      console.log(result.data);
      if (result.data) {
        this.mDataArrayappointment = result.data;
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Show data ",
          "Data not found ",
          "text-h5 red--text text-center"
        );
        return;
      }

      this.showfilenameappointment = file_no;
      this.dialogAppointment = true;
      // localStorage.setItem(server.MODELMENU, 6);
      // this.$router.push(`/appointment-trademark-list`);
    },
    async closedialogAppointment() {
      this.dialogAppointment = false;
    },
    async openAppointmentadd(file_no) {
      localStorage.setItem(server.MODELMENU, 6);
      // this.$router.push(`/appointmenttm-add/${file_no}`);
      window.open(`/appointmenttm-add/${file_no}/null`);
    },
    async openeditItem(mastertm_id) {
      // this.$router.push(`/master-trademark-edit/${mastertm_id}`);
      window.open(`/master-trademark-edit/${mastertm_id}`);
    },

    deleteItem(item) {
      this.deletedIndex = this.mDataArray.indexOf(item);
      console.log(item.mastertm_id);
      this.deletedItem = item.mastertm_id;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.deletedItem = 0;
      this.dialogDelete = false;
    },
    async deleteItemConfirm() {
      this.$showLoader();
      this.mDataArray.splice(this.deletedIndex, 1);
      this.dialogDelete = false;
      let setflag = 1;
      // 1 = delete
      const resdel = await api.updateMasterTM(this.deletedItem, {
        updatestatus: setflag,
      });
      // const resdel = await api.deleteMasterTM(this.deletedItem);
      console.log(resdel);
      if (resdel.status == 200 || resdel.status == 201) {

        var dataAdd = {
            user_id: localStorage.getItem(server.USER_ID),
            log_in_out_id: localStorage.getItem(server.LOGIN_ID),
            action: "Delete",
            table_name: "mastertm",
            table_id: Number(this.deletedIndex) + 1,
            date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }

        await api.addTransectionLog(dataAdd)

        var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

        // this.loaddatadetails();
        this.closeDelete();
        this.$hideLoader();
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "delete data",
          "delete data success",
          "text-h5 green--text text-center",
          "/reloadpage"
        );
        return;
      } else {
        // this.loaddatadetails();
        this.closeDelete();
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "delete data",
          "can't delete data",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },

    async setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
