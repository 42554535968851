//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import { server } from "@/services/constants";

export default {
    name: "SO-list",
    data(vm) {
        return {
            travelFlag: 0,
            Code: "",
            Description: "",
            Status: "A",
            status_code: [
                    { id: "A", status_name: "Active" },
                    { id: "I", status_name: "Inactive" },
                ],
            travelFlagCode: [
                    { travelFlagValue: 0, travelFlagName: "No" },
                    { travelFlagValue: 1, travelFlagName: "Yes" },
                ],
            mode_oper: "",
            dialogAddData: false,
            dialogAdd: false,
            text_color: "text-h5 green--text text-center",
            title: "green",
            message: "green",
            link: "",
            id_del: 0,
            search: "",
            leave_type: "",
            dialogDelete: false,
            authorize_view: false,
            authorize_add: false,
            authorize_edit: false,
            authorize_del: false,
            toggleEnable: false,
            selectstatus: "",
            mDataArray: [],
            authorize: [],
            headers: [
                {
                    text: "Code",
                    value: "code",
                    class: "bg-colorth tabletextwhite",
                    align: "start",
                    divider: true,
                },
                {
                    text: "Description",
                    value: "description",
                    class: "bg-colorth tabletextwhite",
                    align: "start",
                    divider: true,
                },
                {
                    text: "Status",
                    value: "status_show",
                    class: "bg-colorth tabletextwhite",
                    align: "start",
                    divider: true,
                },
                {
                    text: "Travel Flag ",
                    value: "travelFlagShow",
                    class: "bg-colorth tabletextwhite",
                    align: "start",
                    divider: true,
                },

                {
                    text: "",
                    value: "actions",
                    align: "center",
                    class: "bg-colorth tabletextwhite",
                    divider: true,
                },
            ],
        };
        },
    async beforeCreate() {
        console.log("beforeCreate");
        this.$store.state.navMenu = true ;
    },
    beforeUpdate() {},
    components: {
        SuccessDialog,
        SuccessDialogPush,
    },
    computed: {
        itemsForSelected() {
            if (this.search.length) {
                return this.dataSource.filter((item) => this.search.includes(item));
            }
            return this.dataSource;
        },
    },
    async mounted() {
        await api.checkVersion();
        this.userId = localStorage.getItem(server.USER_ID);
        let yourUrlString = window.location;
        // alert(yourUrlString);
    
        let parser = document.createElement("a");
        parser.href = yourUrlString;
    
        this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    
        // alert("authorize_id:" + this.authorize_id);
        if (this.authorize_id == null || this.authorize_id == 0) {
            // this.$router.push("/login");
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
            true,
            "Authorize Failed!!!",
            "Please Logout And Login Again!!!",
            "text-h5 red--text text-center"
            );
            this.$router.back();
        }
    
        const router_path = parser.pathname.replace("/", "");
    
        const res_auth = await api.getAuthorize(this.userId, router_path);

    
        this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
        this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
        this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
        this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;
    

    
        if (!this.authorize_view) {
            this.$router.back();
        }
    
        // ----------------- Check Authorize ---------------------------
    
        let comp_id = localStorage.getItem(server.COMPANYID);
    
        this.userId = localStorage.getItem(server.USER_ID);
        if (this.userId && api.isLoggedIn()) {
            // this.$router.push("/");
        } else {
            this.$store.state.isLogged = false;
            this.$router.push("/login");
        }

        //load data
        await this.loaddata();
        this.$hideLoader();
    },
    methods: {
        async loaddata() {
            const res_result = await api.getAllAdvanceCodeByCompanyList(localStorage.getItem(server.COMPANYID))  
            this.mDataArray = res_result.data;
            console.log(this.mDataArray)
            this.mDataArray.forEach((item) =>{
                if(item.status == "A"){
                    item.status_show = "Active"
                }else{
                    item.status_show = "Inactive"
                }

                if(item.travel_flag == 0){
                    item.travelFlagShow = "No";
                }else if(item.travel_flag == 1){
                    item.travelFlagShow = "Yes";
                }else{
                    item.travelFlagShow = "";
                }
                
            })
            console.log(res_result.data)
        },
        async opendialogAdd(id){
            this.mode_oper = "Add";
            this.Code = "";
            this.Description = "";
            this.dialogAddData = true;
        },
        async saveaddItem(){
            if (this.Code == "") {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Check your information again",
                "Please enter all the details.",
                "text-h5 red--text text-center"
                );
                this.$refs["Code"].focus();
                return;
            }
            if (this.Description == "") {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                true,
                "Check your information again",
                "Please enter all the details.",
                "text-h5 red--text text-center"
                );
                this.$refs["Description"].focus();
                return;
            }
            

            this.$showLoader()
            var res_oper = [];
            if(this.mode_oper == "Add"){
                var dataitem_add = {
                    code: this.Code,
                    travel_flag: this.travelFlag,
                    description: this.Description,
                    company_id: Number(localStorage.getItem(server.COMPANYID)),
                    status: this.Status,
                }
                 res_oper = await api.createAdvanceCode(dataitem_add)
            }else if(this.mode_oper == "Edit"){
                var dataitem_edit = {
                    code: this.Code,
                    description: this.Description,
                    status: this.Status,
                    travel_flag: this.travelFlag
                }

                res_oper = await api.updateAdvanceCode(this.id_del,dataitem_edit)
            }
            if(res_oper.status == 201){
                    this.dialogAddData = false
                    await this.loaddata();
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "Success",
                        "Data successfully added",
                        "text-h5 green--text text-center",
                    );
                }else{
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "ลบข้อมูล!!!",
                        "ไม่สามารถลบได้!!!",
                        "text-h5 red--text text-center"
                    );
                }
        },
        async closeaddItem(){
            this.dialogAddData = false;
        },
        async openeditItem(data){
            this.id_del = 0;
            this.id_del = data.id;
            this.mode_oper = "Edit";
            this.Code = data.code;
            this.Description = data.description;
            this.Status = data.status;
            this.dialogAddData = true ;
            this.travelFlag = data.travel_flag;
            
            // this.$router.push(`/invioce-title-add/Edit/${id}`);
        },
        async deleteItem(id){
            this.id_del = 0;
            this.id_del = id;
            this.dialogDelete = true;
        },
        async deleteItemConfirm(){
            this.dialogDelete = false;
            this.$showLoader();
            const res_del = await api.deleteAdvanceCode(this.id_del);
            if(res_del.status == 200){
                    await this.loaddata();
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "Success",
                        "Data successfully deleted",
                        "text-h5 green--text text-center",
                    );
                }else{
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                        true,
                        "ลบข้อมูล!!!",
                        "ไม่สามารถลบได้!!!",
                        "text-h5 red--text text-center"
                    );
                }
        },
        async closeDelete(){
            this.dialogDelete = false;
        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },

    },
};
