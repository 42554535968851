var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.otfCheck)?_c('v-card',{staticClass:"pa-16"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Immigration Master")])],1),_c('v-col',{attrs:{"align":"right","color":"primary","cols":"12","md":"6"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"to":"/immigration-master-add","color":"#0A7EA5","rounded":"","dark":"","disabled":!_vm.authorize_add}},[_c('span',[_vm._v("Add")]),_c('v-icon',{attrs:{"small":"","center":""}},[_vm._v("add")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}}),_c('v-col',{attrs:{"cols":"12","md":"1"}})],1)],1),_c('v-col',{staticClass:"d-flex justify-end mb-6",attrs:{"cols":"12","md":"4"}})],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.toggleEnable),expression:"toggleEnable"}]},[_c('v-col',{attrs:{"cols":"12","md":"6"}}),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"DateFrom","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","clearable":""},on:{"click:clear":function($event){_vm.$nextTick(function () {
                  _vm.datefrom = null;
                  _vm.dateto = null;
                })}},model:{value:(_vm.computeddatefromFormatted),callback:function ($$v) {_vm.computeddatefromFormatted=$$v},expression:"computeddatefromFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,1422100704),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.datefrom),callback:function ($$v) {_vm.datefrom=$$v},expression:"datefrom"}})],1)],1),_c('v-col',{attrs:{"cols":"12","xl":"3","lg":"3","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"DateTo","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","clearable":""},on:{"click:clear":function($event){_vm.$nextTick(function () {
                  _vm.datefrom = null;
                  _vm.dateto = null;
                })}},model:{value:(_vm.computeddateToFormatted),callback:function ($$v) {_vm.computeddateToFormatted=$$v},expression:"computeddateToFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,1738846048),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.dateto),callback:function ($$v) {_vm.dateto=$$v},expression:"dateto"}})],1)],1)],1),_c('br'),_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-data-table',{staticClass:"elevation-1   595959",attrs:{"dense":"","search":_vm.search,"headers":_vm.headers,"items":_vm.mDataArray},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.authorize_edit)?_c('v-icon',{staticClass:"ml-1",style:({
              backgroundColor: '#E1F5FE',
              color: '#2196F3',
            }),on:{"click":function($event){return _vm.openeditItem(item.id)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.authorize_del)?_c('v-icon',{staticClass:"ml-1",style:({
              backgroundColor: '#FFEBEE',
              color: '#F44336',
            }),on:{"click":function($event){return _vm.deleteItem(item.id)}}},[_vm._v(" mdi-delete ")]):_vm._e(),(_vm.authorize_add)?_c('v-icon',{staticClass:"ml-1",style:({
              backgroundColor: '#dbd7d0',
              color: '#6E6658',
            }),on:{"click":function($event){return _vm.opentransactionadd(item.file_number)}}},[_vm._v(" mdi-calendar-plus ")]):_vm._e(),_c('v-icon',{style:({
              backgroundColor: '#d7dee0',
              color: '#183981',
            }),on:{"click":function($event){return _vm.opentransactionlist(item.file_number)}}},[_vm._v(" mdi-calendar-check ")])]}}],null,false,494872044)})],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this file?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1200px"},model:{value:(_vm.dialogtransaction),callback:function ($$v) {_vm.dialogtransaction=$$v},expression:"dialogtransaction"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Transaction for Master "+_vm._s(_vm.showfilenameappointment))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"#254E58"},on:{"click":_vm.closedialogtransaction}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerstransaction,"items":_vm.mDataArraytransaction},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.authorize_edit)?_c('v-icon',{style:({
                backgroundColor: '#E1F5FE',
                color: '#2196F3',
              }),on:{"click":function($event){return _vm.openeditTabletransaction(item.id)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}}])})],1),_c('v-card-actions')],1)],1),_c('v-dialog',{attrs:{"max-width":"340px","persistent":""},model:{value:(_vm.dialogOTPVerification),callback:function ($$v) {_vm.dialogOTPVerification=$$v},expression:"dialogOTPVerification"}},[_c('v-card',{staticClass:"justify-center align-center"},[_c('v-card-title',{staticClass:"text-h5 justify-center align-center"},[_vm._v("OTP Verification")]),_c('v-row',{staticClass:"ma-0 justify-center align-center"},[_vm._v(" Enter the OTP your received at ")]),_c('v-row',{staticClass:"ma-0 justify-center align-center"},[_vm._v(" "+_vm._s(_vm.dataotp.email)+" ")]),_c('v-row',{staticClass:"ma-0 justify-center align-center mt-2"},[_c('v-col',{attrs:{"cols":"12","xs":"2","sm":"2","md":"2"}},[_c('v-text-field',{ref:"otp1",staticClass:"justify-center ",attrs:{"autofocus":"","outlined":"","dense":"","hide-details":""},on:{"focus":function($event){_vm.$nextTick(function () {
     _vm.$refs.otp1.focus()
    });},"keyup":function($event){_vm.$nextTick(function () {
      if(_vm.sendotpdata.otp1 == ""){
      }else{
        if(Number(_vm.sendotpdata.otp1) || _vm.sendotpdata.otp1=="0"){
          _vm.$refs.otp2.focus()
        }else{
          _vm.sendotpdata.otp1="";
        }
      }
    });}},model:{value:(_vm.sendotpdata.otp1),callback:function ($$v) {_vm.$set(_vm.sendotpdata, "otp1", $$v)},expression:"sendotpdata.otp1"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"2","sm":"2","md":"2"}},[_c('v-text-field',{ref:"otp2",staticClass:"justify-center ",attrs:{"outlined":"","dense":"","hide-details":""},on:{"keyup":function($event){_vm.$nextTick(function () {
      if(_vm.sendotpdata.otp2 == ""){
      }else{
        if(Number(_vm.sendotpdata.otp2) || _vm.sendotpdata.otp2=="0"){
          _vm.$refs.otp3.focus()
        }else{
          _vm.sendotpdata.otp2="";
        }
      }
    });}},model:{value:(_vm.sendotpdata.otp2),callback:function ($$v) {_vm.$set(_vm.sendotpdata, "otp2", $$v)},expression:"sendotpdata.otp2"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","xs":"2","sm":"2","md":"2"}},[_c('v-text-field',{ref:"otp3",staticClass:"justify-center ",attrs:{"outlined":"","dense":"","hide-details":""},on:{"keyup":function($event){_vm.$nextTick(function () {
      if(_vm.sendotpdata.otp3 == ""){
      }else{
        if(Number(_vm.sendotpdata.otp3) || _vm.sendotpdata.otp3=="0"){
          _vm.$refs.otp4.focus()
        }else{
          _vm.sendotpdata.otp3="";
        }
      }
    });}},model:{value:(_vm.sendotpdata.otp3),callback:function ($$v) {_vm.$set(_vm.sendotpdata, "otp3", $$v)},expression:"sendotpdata.otp3"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","xs":"2","sm":"2","md":"2"}},[_c('v-text-field',{ref:"otp4",staticClass:"justify-center ",attrs:{"outlined":"","dense":"","hide-details":""},on:{"keyup":function($event){_vm.$nextTick(function () {
      if(_vm.sendotpdata.otp4 == ""){
      }else{
        if(Number(_vm.sendotpdata.otp4) || _vm.sendotpdata.otp4=="0"){
          _vm.$refs.otp5.focus()
        }else{
          _vm.sendotpdata.otp4="";
        }
      }
    });}},model:{value:(_vm.sendotpdata.otp4),callback:function ($$v) {_vm.$set(_vm.sendotpdata, "otp4", $$v)},expression:"sendotpdata.otp4"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","xs":"2","sm":"2","md":"2"}},[_c('v-text-field',{ref:"otp5",staticClass:"justify-center ",attrs:{"outlined":"","dense":"","hide-details":""},on:{"keyup":function($event){_vm.$nextTick(function () {
      if(_vm.sendotpdata.otp5 == ""){
      }else{
        if(Number(_vm.sendotpdata.otp5) || _vm.sendotpdata.otp5=="0"){
          _vm.$refs.otp6.focus()
        }else{
          _vm.sendotpdata.otp5="";
        }
      }
    });}},model:{value:(_vm.sendotpdata.otp5),callback:function ($$v) {_vm.$set(_vm.sendotpdata, "otp5", $$v)},expression:"sendotpdata.otp5"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","xs":"2","sm":"2","md":"2"}},[_c('v-text-field',{ref:"otp6",staticClass:"justify-center ",attrs:{"outlined":"","dense":"","hide-details":""},on:{"keyup":function($event){_vm.$nextTick(function () {
     
        if(Number(_vm.sendotpdata.otp6) || _vm.sendotpdata.otp6=="0"){
        }else{
          _vm.sendotpdata.otp6="";
        }
    });}},model:{value:(_vm.sendotpdata.otp6),callback:function ($$v) {_vm.$set(_vm.sendotpdata, "otp6", $$v)},expression:"sendotpdata.otp6"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.sendOTPConfirm}},[_vm._v("Confirm OTP")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){return _vm.goToDashboard()}}},[_vm._v("Cancel")]),_c('v-spacer')],1)],1)],1),_c('SuccessDialog',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message}}),_c('SuccessDialogPush',{attrs:{"status":_vm.dialogAdd,"text_color":_vm.text_color,"title":_vm.title,"message":_vm.message,"link":_vm.link}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }